export const getPermissions = (clientName = "") => {
  let origin = window.location.origin;
  if (origin.includes("demo")) {
    return {
      dataAuditReport: [],
      ancillary: [],
      professional: [
        "/",
        "/historicalrequests",
        "/RatechoiceviewByTIN",
        "/RateInsightsByTIN",
      ],
      facility: [
        "/",
        "/payersPriceCompareCPT-HCPCS",
        //"/procedureCostByHospital",

        "/providersExtractCPT",
        //    "/providersExtractHCPCS",
        //    "/providersExtractCPTChart",
        "/facilityScoreCard",
        "/payersPriceCompareDRG",
        "/topCpt",
        "/topDrg",
        "/historicalrequests",
      ],
      disableDbDropDown: true,
      showDemoButton: true,
      isDemo: true,
      shownoteInHomeModal: true,
    };
  }
  switch (clientName) {
    case "excellus":
    case "wellmark":
    case "shs":
      return {
        dataAuditReport: [
          "/",
          "/report",
          "/procedureCostByHospital",
          "/drgRefRable",
        ],
        ancillary: [
          "/",
          "/payersPriceCompareCPT-HCPCS",
          "/payersPriceCompareAPC",
          "/payersPriceCompareDRG",
          "/filteredDrgList",
          "/topCpt",
          "/topDrg",
          //"/providersExtractCPTChart",
          // '/historicalrequests',
          // "/DRGcostBreakdown",
        ],
        professional: [
          "/",
          // "/providersCPTExtract",
          // "/providersHCPCSExtract",
          "/topCpt",
          "/historicalrequests",
          "/Ratechoiceview",
          "/ProfessionalRateUtilizationview",
          "/ProfessionalRateUtilizationviewByTIN",
          "/RatechoiceviewByTIN",
          "/RateInsightsByTIN",
          //"/Rateinsightview",
        ],
        facility: [
          "/",
          "/payersPriceCompareCPT-HCPCS",
          "/payersPriceCompareAPC",
          "/payersPriceCompareDRG",
          "/providersCPTExtract",
          // "/providersExtractCPT",
          "/filteredDrgList",
          "/topCpt",
          "/topDrg",
          //'/historicalrequests',
          // "/DRGcostBreakdown",

          // '/facilityScoreCard',
        ],
      };
    case "la_county":
      return {
        dataAuditReport: [],
        ancillary: [
          "/",
          "/payersPriceCompareCPT-HCPCS",
          //"/procedureCostByHospital",

          "/providersExtractCPT",
          //    "/providersExtractHCPCS",
          //    "/providersExtractCPTChart",
          //'/payersPriceCompareDRG',
          "/topCpt",
          //'/topDrg',
          "/historicalrequests",
          //"/RateInsightsByTIN",
        ],
        professional: [],
        facility: [],
      };

    case "axenehp":
      return {
        dataAuditReport: [
          "/",
          "/report",
          "/procedureCostByHospital",
          "/drgRefRable",
        ],
        ancillary: [
          "/",
          "/payersPriceCompareCPT-HCPCS",
          "/payersPriceCompareAPC",
          "/payersPriceCompareDRG",
          "/filteredDrgList",
          "/topCpt",
          "/topDrg",
          //"/providersExtractCPTChart",
          // '/historicalrequests',
          // "/DRGcostBreakdown",
        ],
        professional: [
          "/",
          // "/providersCPTExtract",
          // "/providersHCPCSExtract",
          "/topCpt",
          "/historicalrequests",
          "/Ratechoiceview",
          // "/ProfessionalRateUtilizationview",
          "/ProfessionalRateUtilizationviewByTIN",
          "/RatechoiceviewByTIN",
          "/RateInsightsByTIN",
          //"/Rateinsightview",
        ],
        facility: [
          "/",
          "/payersPriceCompareCPT-HCPCS",
          "/payersPriceCompareAPC",
          "/payersPriceCompareDRG",
          "/providersCPTExtract",
          // "/providersExtractCPT",
          "/filteredDrgList",
          "/topCpt",
          "/topDrg",
          //'/historicalrequests',
          // "/DRGcostBreakdown",

          // '/facilityScoreCard',
        ],
      };

    default:
      return {
        dataAuditReport: [],
        ancillary: [],
        professional: [],
        professional: [
          "/",
          "/historicalrequests",
          "/RatechoiceviewByTIN",
          "/RateInsightsByTIN",
        ],
        facility: [
          "/",
          "/payersPriceCompareCPT-HCPCS",
          //"/procedureCostByHospital",

          "/providersExtractCPT",
          //    "/providersExtractHCPCS",
          //    "/providersExtractCPTChart",
          "/facilityScoreCard",
          "/payersPriceCompareDRG",
          "/topCpt",
          "/topDrg",
          "/historicalrequests",
        ],
        disableDbDropDown: true,
        showDemoButton: true,
        isDemo: true,
        shownoteInHomeModal: true,
      };
  }
};

export const getFilteredRoutes = (routes, permissions, module) => {
  if (!module) {
    module = Object.keys(routes).find((key) => permissions[key].length > 0);
  }

  return routes[module]?.filter(
    (i) => permissions[module]?.findIndex((it) => it == i.path) > -1
  );
};

export const getFilteredSideList = (permissions, fullist) => {
  return fullist?.filter(
    (i) => permissions?.findIndex((it) => it == i.path) > -1
  );
};
